import React from "react";

function App() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "#41B3A3",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          color: "white",
          fontWeight: "bold",
          fontSize: "24px",
          maxWidth: "80vw",
          textAlign: "center",
        }}
      >
        Site is closed for an indefinite period of time starting 1. april 2022.
        <br />
        Hope all of you understand, and have enjoyed using the service while it
        lasted.
        <br />
        <br />
        Thanks - algn
      </div>
    </div>
  );
}

export default App;
